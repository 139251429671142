<template>
  <section class="home-questions">
    <div class="container">
      <div class="wrapper">
        <div class="wrapper-img">
          <img src="@/assets/img/home/questions/question-img.png">
        </div>
        <div class="content">
          <h1 class="section-title">
            Частые вопросы
          </h1>
          <app-accordion @accordion-changed="$emit('fullpage-rebuild')">
            <app-accordion-item title="Как работает услуга?">
              <p class="more">Принцип работы услуг основан на присвоении уникальных идентификационных номеров объектам охраны (ключам, телефонам, паспортам, багажу, животным, людям и т.д.). </p>

              <p class="fs--small description">Наши брелоки, наклейки, багажные бирки, браслеты и другая продукция (метки) содержат уникальные идентификационные номера. Если Вы теряете вещь с такой меткой, а кто-то её находит, то Нашедший звонит на номер бесплатной круглосуточной службы поддержки (этот номер тоже указан на всех брелоках, наклейках и т.д.), называет уникальный номер, и специалисты компании CLICKTOID по этому номеру определяют владельца, сообщают Вам о находке и доставляют утерянную вещь по указанному Вами адресу.</p>
            </app-accordion-item>
            <app-accordion-item title="Как пользоваться услугой?">
              <p class="more">Приобретенные метки с идентификационным номером необходимо прикрепить на личные вещи. В случае утери вещи с такой меткой, Вы сообщаете об этом в нашу службу поддержки. </p>

              <p class="more">После звонка Нашедшего в контакт-центр, специалист CLICKTOID связывается с Владельцем найденной вещи и организовывает возврат находки Владельцу курьером. </p>

              <p class="more">За возврат найденной вещи CLICKTOID вручает Нашедшему подарок, если он предусмотрен. </p>
              <template v-slot:other>
                <app-button
                  class="mb-m"
                  title="К услугам"
                  description="Переход к каталогу"
                  to="/#products"
                >
                  <icon-debug class="icon" />
                </app-button>
              </template>
            </app-accordion-item>
            <app-accordion-item title="Активация пакета услуг">
              <p class="fs--small description">
                ВАЖНО: АКТИВИРОВАТЬ ПАКЕТ УСЛУГ НЕОБХОДИМО В ТЕЧЕНИЕ 5 ДНЕЙ С МОМЕНТА ЕГО ОПЛАТЫ
              </p>
              <p class="fs--small description">
                ДЛЯ АКТИВАЦИИ ПАКЕТА УСЛУГ ВАМ НЕОБХОДИМО:
              </p>
              <ul class="fs--small description">
                <li class="description">
                  Пройти по ссылке <router-link to="activation#hero">«АКТИВАЦИЯ»</router-link>
                </li>
                <li class="description">
                  Заполнить все обязательные поля (поля, помеченные обозначением (*))
                </li>
                <li class="description">
                  Ознакомиться с условиями Соглашения и принять его условия, проставив галочку
                </li>
                <li class="description">
                  Подтвердить, что Вы не робот
                </li>
                <li class="description">
                  Пройти по активной ссылке «АКТИВИРОВАТЬ»
                </li>
              </ul>
            </app-accordion-item>
            <app-accordion-item title="Смена держателя пакета услуг">
              <p class="fs--small description">
                ВАЖНО: ВЫ МОЖЕТЕ ПРИОБРЕСТИ ПАКЕТ УСЛУГ В ПОДАРОК.
              </p>
              <p class="fs--small description">
                ДЛЯ СМЕНЫ ДЕРЖАТЕЛЯ ПАКЕТА УСЛУГ ВАМ НЕОБХОДИМО:
              </p>
              <ul class="fs--small description">
                <li class="description">
                  Пройти по ссылке <router-link to="activation#hero">«СМЕНА ДЕРЖАТЕЛЯ»</router-link>
                </li>
                <li class="description">
                  Заполнить все обязательные поля, для проверки текущего держателя метки (поля, помеченные обозначением (*))
                </li>
                <li class="description">
                  При положительном результате проверки - заполнить все обязательные поля (поля, помеченные обозначением (*)) с информацией о новом держателе метки
                </li>
                <li class="description">
                  Ознакомиться с условиями Соглашения и принять его условия, проставив галочку
                </li>
                <li class="description">
                  Подтвердить, что Вы не робот
                </li>
                <li class="description">
                  Пройти по активной ссылке «ПОДТВЕРДИТЬ»
                </li>
              </ul>
            </app-accordion-item>
          </app-accordion>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AppAccordion from '@/components/common/AppAccordion'
import AppAccordionItem from '@/components/common/AppAccordionItem'
import AppButton from '@/components/common/AppButton'
import IconDebug from '@/assets/img/icons/debug.svg'

export default {
  name: 'HomeQuestions',
  components: {
    AppAccordion,
    AppAccordionItem,
    AppButton,
    IconDebug
  }
}
</script>

<style lang="scss" scoped>
  .home-questions {
    background: url('~@/assets/img/home/questions/questions-pipe.png') center -2000px / cover no-repeat;
    transition: 1s;
    &.active {
      background-position: center;
      .wrapper-img {
        left: 0 !important;
      }
      .content {
        right: 0 !important;
      }
    }
    .container {
      min-height: 100vh;
      align-items: center;
      justify-content: center;
      @include max-w-lg {
        padding-top: 120px;
      }
    }
    .wrapper {
      height: 100%;
      display: flex;
      align-items: flex-start;
      @include max-w-sm {
        flex-direction: column;
      }
      .wrapper-img, .content{
        position: relative;
        transition: 1s;
      }
      .wrapper-img {
        left: -2000px;
        min-width: 350px;
        max-width: 500px;
        margin-top: 40px;
        margin-right: 120px;
        opacity: .9;
        transform: rotate(-4.13deg);
        @include max-w-lg {
          min-width: 280px;
          margin-right: 60px;
        }
        @include max-w-sm {
          margin: 0 auto;
          margin-bottom: 60px;
          max-width: 300px;
        }
      }
      .content {
        right: -2000px;
        max-width: 530px;
        width: 530px;
        @include max-w-sm {
          max-width: none;
          width: 100%;
        }
        .accordion-questions {
          scrollbar-width: none;
          &::-webkit-scrollbar { width: 0 !important; }
          overflow-y: scroll;
          height: 550px;
          .accordion-item {
            .wrapper-btn {
              .icon {
                path {
                  fill: $dark;
                  stroke: $dark;
                }
              }
            }
            ul {
              padding-left: 16px;
              li {
                list-style-type: decimal;
              }
            }
            .more {
              margin-bottom: 1em;
            }
            .description {
              font-weight: 300;
              margin-bottom: 1em;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
</style>