<template>
  <div class="product-item">
    <div class="wrapper-img">
      <img :src="require(`@/assets/img/products/${product.img}`)" alt="">
    </div>
    <div class="content">
      <p class="title fs--medium fw--bold">
        {{ product.title }}
      </p>
      <p class="snippet">
        {{ product.snippet }}
      </p>
      <p class="description fs--small">
        {{ product.description }}
      </p>
      <p v-if="product.price" class="price fs--medium">
        {{ product.price }} <span class="currency">₽</span>
      </p>
    </div>
    <app-button
  v-if="product.price"
  title="Купить"
  primary
  @click="redirectToProduct">
  <icon-cart-outline class="icon-cart" />
</app-button>
    <app-button
      v-else
      :title="operationType"
      description="Активация возможна, если вы уже приобрели товар"
      primary
    >
      <div class="circle-plus" />
    </app-button>
    <app-link :to="`/product/${product.id}`">
      Подробнее об услуге
    </app-link>
  </div>
</template>

<script>
import AppButton from '@/components/common/AppButton'
import AppLink from '@/components/common/AppLink'
import IconCartOutline from '@/assets/img/icons/cart-outline.svg'
//import { mapActions } from 'vuex'

export default {
  name: 'ProductItem',
  components: {
    AppButton,
    AppLink,
    IconCartOutline
  },
  props: {
    operationType: {
      type: String,
      default: 'Купить'
    },
    product: {
      type: Object,
      required: true
    }
  },
  methods: {
    redirectToProduct() {
      const link = this.product.link;
      window.location.href = link;
    }
  }
}
</script>

<style lang="scss" scoped>
  .product-item {
    position: relative;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    @include max-w-xs {
      max-width: 280px;
    }
    .wrapper-img {
      position: absolute;
      align-self: center;
      z-index: 2;
      filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.16));
      height: 200px;
      width: 280px;
      @include max-w-xs {
        height: 140px;
      }
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
    .content {
      margin-top: 100px;
      padding: 120px 16px 16px 16px;
      margin-bottom: 20px;
      background: linear-gradient(180deg, rgba(250, 250, 250, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%);
      border-radius: $border-radius-large;
      @include max-w-xs {
        padding: 60px 16px 16px 16px;
      }
      .title {
        margin-bottom: 4px;
      }
      .snippet {
        color: $gray;
      }
      .snippet, .description {
        margin-bottom: 10px;
      }
      .price {
        font-weight: bold;
        .currency {
          color: $gray;
        }
      }
    }
    .circle-plus {
      border-color: $white;
      &::before, &::after {
        background: $white;
      }
    }
    .wrapper-btn {
      margin-bottom: 20px;
      .icon-cart {
        display: flex;
        path {
          fill: $white;
        }
      }
      &:first-child {
        margin-bottom: 20px;
      }
    }
  }
</style>
