<template>
  <section class="home-products">
    <div class="container">
      <div class="wrapper ta--center mb-xl">
        <h1 class="section-title">
          Наши продукты
        </h1>
        <div class="divider" />
        <p>
          Выберите один из представленных продуктов компании CLICKTOID, <br> помогающий вернуть утерянную вещь из любой точки мира.
        </p>
      </div>
    </div>
    <swiper
      v-if="products.length"
      ref="swiper"
      class="swiper mb-l"
      :class="{ padding : products.length > 2 }"
      :options="swiperOptions"
    >
      <swiper-slide
        v-for="item in products"
        :key="item.id"
        class="product"
        :product="item"
      >
        <product-item :product="item" />
      </swiper-slide>
    </swiper>
  </section>
</template>

<script>
import ProductItem from '@/components/ProductItem'

export default {
  name: 'HomeProducts',
  components: {
    ProductItem
  },
  data () {
    return {
      swiperOptions: {
        spaceBetween: 40,
        freeMode: true,
        breakpoints: {
          576: {
            slidesPerView: 2,
            spaceBetween: 80
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 120,
          }
        }
      },
      products: [
        {
          id: 1,
          img: 'keys.png',
          title: 'Ключи на месте',
          snippet: 'Брелок для ключей с сервисом на 1 год',
          description: 'С услугой «Ключи на месте» Вы минимизируете риски от потери своих ключей! Брелок CLICKTOID имеет уникальный ID номер, информацию для Нашедшего и телефон круглосуточной Службы поддержки.',
          price: '2000',
          link: 'https://www.ozon.ru/product/brelok-dlya-klyuchey-s-zashchitoy-ot-poteri-i-kruglosutochnoy-podderzhkoy-1151900875/?_bctx=CAQQ4fpJ&asb=DMl33RmPtGXMn9h14VBFJMj83rGfYCrBvduWfZdc2yw%253D&asb2=W6Krg395l0rvsUrMZ0Ee_dizLsLmEd-MR6vYWa7LY2aYnwVtU4j7Uv1BufOiXxQOq1R2dBviFiz9tMWbmrALVg&avtc=1&avte=2&avts=1728143462&hs=1'
        },
        {
          id: 2,
          img: 'birka.png',
          title: 'Любимый друг',
          snippet: 'Кулон для животного с сервисом на 1 год',
          description: 'Услуга «Любимый друг» предоставит Вам возможность оперативно получить информацию о метонахождении Вашего питомца, если он потерялся!',
          price: '2000',
          link: 'https://www.ozon.ru/product/adresnik-dlya-zhivotnogo-s-unikalnym-id-i-zashchitoy-ot-poteri-1168153202/?_bctx=CAQQ4fpJ&asb=MFoyTwEwmLTG8plGA%252Fp1x0QECkhtbODjkSQLGp%252BtLzc%253D&asb2=N49XOO8KwR0fcjPfqQgOn1ze3n5HlgXuTTWCRlocH69Ej9mkpoRD9dZKFrTQBjxY4H9cfj2I4crW-Ix1XHGiAg&avtc=1&avte=2&avts=1728143462&hs=1'
        },
        {
          id: 3,
          img: 'passport.png',
          title: 'Защита документов',
          snippet: 'Наклейка с сервисом на 1 год',
          description: 'Услуга «Защита документов» необходима каждому. Потеря гражданского паспорта или водительского удостоверения принесет немало проблем, а утрата загранпаспорта в путешествии может испортить весь отпуск.',
          price: '2000',
          link: 'https://www.ozon.ru/product/nakleyka-stiker-na-telefon-noutbuk-pasport-s-zashchitoy-ot-poteri-i-kruglosutochnoy-podderzhkoy-1144872214/?_bctx=CAQQ4fpJ&asb=cU5pDqBT%252Bhp1nSgxVevN4QxBnyjkj3S9TNQpS%252FKE6hg%253D&asb2=9e_MTcTRCESvVrDkQ_izIOfGmZURwNbWtG5D_VXuVImMgsLuZbvxTfr7pxB4xI_xAi5EfRDWcGx9b_JUbNGYGg&avtc=1&avte=2&avts=1728143462&hs=1'
        },
        {
          id: 4,
          img: 'paket.png',
          title: 'Заабота о близких',
          snippet: 'Набор меток для близких с сервисом на 1 год',
          description: 'С услугой «Забота о близких» вы снизите риски потери родных. Визитка, кулон и браслет с уникальным ID номером содержат информацию для нашедшего и телефон круглосуточной службы поддержки.',
          price: '4000',
          link: 'https://www.ozon.ru/product/nabor-dlya-puteshestviy-s-zashchitoy-ot-poteri-blizkih-1152798264/?_bctx=CAQQ4fpJ&asb=voFD63gC%252Br7n0W0spoOJXdE7QyHZC4WBxfNglu2NXmg%253D&asb2=tNENSbU4OJdHBKdK8XIRh9ShVPKMS-QgYnysz7Grvcjtds622dEbbSBRQFgMYK2IbzbPrzDkgId8wqsqzXdvmQ&avtc=1&avte=2&avts=1728143462&hs=1'
        },
        {
          id: 5,
          img: 'paket-2.png',
          title: 'Защита вещей',
          snippet: 'Набор меток для вещей с сервисом на 1 год',
          description: 'С услугой «Защита вещей» вы снижаете риск утраты своих вещей. Бирка на багаж, брелок на ключи и наклейки на устройства и документы оснащены уникальным ID номером, информацией для нашедшего и телефоном круглосуточной службы поддержки.',
          price: '4000',
          link: 'https://www.ozon.ru/product/nabor-dlya-puteshestviy-s-zashchitoy-ot-poteri-veshchey-i-kruglosutochnoy-podderzhkoy-po-vsemu-miru-1152760388/?_bctx=CAQQ4fpJ&asb=PiMzS9Oebr%252FdV3UPucuJ3Otkezv0UX7Y5w4bX%252BFQEYM%253D&asb2=vFWNeQw0f0iAoyxj358n8QpesOm5Ra3giQOZ0W2XcXEDpM05vLgzPigsQ7_axYlQ9wNKcHIZt5GI0JQPPSFX7g&avtc=1&avte=2&avts=1728143462&hs=1'
        },
        {
          id: 6,
          img: 'paket-3.png',
          title: 'Максимальная защита',
          snippet: 'Набор меток для вещей и близких с сервисом на 1 год',
          description: 'С услугой «Максимальная защита» вы сводите к минимуму риск потери как вещей, так и близких. Браслет, карта, кулон, бирка, брелок и наклейки оснащены уникальным ID номером, информацией для нашедшего и телефоном круглосуточной службы поддержки.',
          price: '6000',
          link: 'https://www.ozon.ru/product/nabor-dlya-puteshestviy-s-zashchitoy-ot-poteri-veshchey-i-blizkih-1152803311/?_bctx=CAQQ4fpJ&asb=VUeva67by8MutFWdxvcdzoyTrtFROX7E0ZORmH3OIqQ%253D&asb2=zuvOcuKnFfWTkZlG2kCKeQ_CAvWnYYcOo9e2Y6PaHpisnKuuo_kCLF2Xjzd4FyeC&avtc=1&avte=2&avts=1728143462&hs=1'
        }
      ]
    }
  },
  created () {
    this.swiperOptions.centeredSlides = this.products.length < 3
  }
}
</script>

<style lang="scss" scoped>
  .home-products {
    .container {
      flex-grow: 0;
      .wrapper {
        margin: 0 auto 20px auto;
        max-width: 864px;
      }
    }
    .swiper {
      overflow: visible;
      max-width: 100%;
      &.padding {
        padding-left: 120px;
        @include max-w-xs {
          padding-left: 20px;
        }
      }
      .product {
        max-width: 305px;
      }
    }
  }
</style>
