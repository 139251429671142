<template>
  <section class="home-hero">
    <div class="container">
      <div class="wrapper">
        <div class="wrapper-btn-finding-global">
          <app-button
            class="btn-finding global"
            title="Сообщить о находке"
            description="Если вы нашли вещь с меткой, перейдите по данной кнопке"
            @click.native="$modal.show('modal-finding')"
          >
            <icon-product class="icon" />
          </app-button>
          <div class="control desktop">
            <button class="btn-direction" @click="changeSlide('prev')">
              <icon-arrow-left class="arrow-icon" />
            </button>
            <button
              v-for="item in sliderControl"
              :key="item.id"
              class="control-btn"
              :class="{ active : item.active }"
              @click="changeSlide(item)"
            />
            <button class="btn-direction" @click="changeSlide('next')">
              <icon-arrow-right class="arrow-icon" />
            </button>
          </div>
        </div>
        <div class="control mobile">
          <button class="btn-direction" @click="changeSlide('prev')">
            <icon-arrow-left class="arrow-icon" />
          </button>
          <button
            v-for="item in sliderControl"
            :key="item.id"
            class="control-btn"
            :class="{ active : item.active }"
            @click="changeSlide(item)"
          />
          <button class="btn-direction" @click="changeSlide('next')">
            <icon-arrow-right class="arrow-icon" />
          </button>
        </div>
        <div class="slide-item" :class="{ active : sliderControl[0].active}">
          <img class="blur-img docs" src="@/assets/img/home/hero/docs.png">
          <img class="blur-img umbrella" src="@/assets/img/home/hero/umbrella.png">
          <img class="blur-img bag" src="@/assets/img/home/hero/bag.png">
          <img class="blur-img case" src="@/assets/img/home/hero/case.png">
          <div class="content main-content">
            <div class="mobile-step-description ta--center">
              <p>{{ activeStep ? activeStep.description : 'Здесь отображаются подсказки...' }}</p>
            </div>
            <h1 class="section-title desktop-title">
              Сервис для защиты <br> от потери документов, гаджетов, питомцев <br> и людей
            </h1>
            <div class="description">
              <div class="divider" />
              <p class="description mb-l">
                КликТуАйДи позволяет легко вернуть потерянные вещи из любой точки мира благодаря специальным меткам с уникальным ID и сервисом для их защиты.
              </p>
            </div>
            <app-button
              class="btn-finding btn-global"
              title="Сообщить о находке"
              description="Если вы нашли вещь с меткой, перейдите по данной кнопке"
              @click.native="$modal.show('modal-finding')"
            >
              <icon-product class="icon" />
            </app-button>
          </div>
          <div class="wrapper-img wrapper-main-img">
            <img class="main-img" src="@/assets/img/home/hero/hero-slide-one-img.png" @load="onLoad">
            <div class="wrapper-info-steps">
              <hero-info-step
                v-for="item in instructionSteps"
                :key="item.id"
                :class="[item.class, { active: item.active }]"
                :step="item"
                @mouseover.native="changeActiveStep(item)"
                @mouseleave.native="startStepsAnimation()"
              />
            </div>
          </div>
          <h1 class="section-title mobile-title">
            Сервис для защиты <br> от потери документов, гаджетов, питомцев <br> и людей
          </h1>
        </div>
        <div class="slide-item" :class="{ active : sliderControl[1].active}">
          <div class="content">
            <h1 class="section-title">
              Защита документов
            </h1>
            <div class="description">
              <div class="divider" />
              <p class="description mb-m">
                Специальная наклейка с уникальным ID номером, помогающая вернуть утерянные документы.
              </p>
              <app-button
                class="btn-purchase mb-m"
                title="Купить"
                description="Переход к странице товара"
                primary
                @click="redirectToProduct(2)"
              >
                <icon-cart class="icon-cart" />
              </app-button>
            </div>
            <app-button
              class="btn-finding"
              title="Сообщить о находке"
              description="Если вы нашли вещь с меткой, перейдите по данной кнопке"
              @click.native="$modal.show('modal-finding')"
            >
              <icon-product class="icon" />
            </app-button>
          </div>
          <div class="wrapper-img-secondary img-circle">
            <img class="main-img" src="@/assets/img/products/passport.png" alt="">
          </div>
        </div>
        <div class="slide-item" :class="{ active : sliderControl[2].active}">
          <div class="content">
            <h1 class="section-title">
              Ключи на месте
            </h1>
            <div class="description">
              <div class="divider" />
              <p class="description mb-m">
                Специальный брелок с уникальным ID номером, помогающий вернуть утерянные ключи.
              </p>
              <app-button
                class="btn-purchase mb-m"
                title="Купить"
                description="Переход к странице товара"
                primary
                @click="redirectToProduct(1)"
              >
                <icon-cart class="icon-cart" />
              </app-button>
              <app-button
                class="btn-finding"
                title="Сообщить о находке"
                description="Если вы нашли вещь с меткой, перейдите по данной кнопке"
                @click.native="$modal.show('modal-finding')"
              >
                <icon-product class="icon" />
              </app-button>
            </div>
          </div>
          <div class="wrapper-img-secondary img-circle">
            <img class="main-img keys" src="@/assets/img/products/keys.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import AppButton from '@/components/common/AppButton'
import HeroInfoStep from '@/components/home/inner/HeroInfoStep'
import IconArrowRight from '@/assets/img/icons/arrow-right.svg'
import IconArrowLeft from '@/assets/img/icons/arrow-left.svg'
import IconCart from '@/assets/img/icons/cart.svg'
import IconProduct from '@/assets/img/icons/product.svg'

export default {
  name: 'HomeHero',
  components: {
    AppButton,
    HeroInfoStep,
    IconArrowRight,
    IconArrowLeft,
    IconCart,
    IconProduct
  },
  data () {
    return {
      activeStepNumber: null,
      interval: null,
      instructionSteps: [
        {
          id: 1,
          description: 'Оформите один из нескольких вариантов услуг',
          class: 'first',
          active: false
        },
        {
          id: 2,
          description: 'Вы активируете пакет услуг и прикрепляете метки',
          class: 'second',
          active: false
        },
        {
          id: 3,
          description: 'Когда Ваша вещь будет найдена, мы сообщим Вам об этом',
          class: 'third',
          active: false
        },
        {
          id: 4,
          description: 'CLICKTOID доставляет найденную вещь в любое удобное для Вас время и место',
          class: 'fourth',
          active: false
        }
      ],
      sliderControl: [
        {
          id: 0,
          active: false
        },
        {
          id: 1,
          active: false
        },
        {
          id: 2,
          active: false
        }
      ]
    }
  },
  computed: {
    ...mapState(['orderSuccessful']),
    activeStep() {
      return this.instructionSteps.find(item => item.active)
    }
  },
  mounted () {
    if (this.orderSuccessful) {
      this.$modal.show('modal-order-successful')
      this.$store.dispatch('setOrderSuccessful', false)
    }
  },
  methods: {
    redirectToProduct(id) {
      let link = "https://www.ozon.ru/product/nakleyka-stiker-na-telefon-noutbuk-pasport-s-zashchitoy-ot-poteri-i-kruglosutochnoy-podderzhkoy-1144872214/?_bctx=CAQQ4fpJ&asb=cU5pDqBT%252Bhp1nSgxVevN4QxBnyjkj3S9TNQpS%252FKE6hg%253D&asb2=9e_MTcTRCESvVrDkQ_izIOfGmZURwNbWtG5D_VXuVImMgsLuZbvxTfr7pxB4xI_xAi5EfRDWcGx9b_JUbNGYGg&avtc=1&avte=2&avts=1728143462&hs=1"
      if (id != 2){
        link = "https://www.ozon.ru/product/brelok-dlya-klyuchey-s-zashchitoy-ot-poteri-i-kruglosutochnoy-podderzhkoy-1151900875/?_bctx=CAQQ4fpJ&asb=DMl33RmPtGXMn9h14VBFJMj83rGfYCrBvduWfZdc2yw%253D&asb2=W6Krg395l0rvsUrMZ0Ee_dizLsLmEd-MR6vYWa7LY2aYnwVtU4j7Uv1BufOiXxQOq1R2dBviFiz9tMWbmrALVg&avtc=1&avte=2&avts=1728143462&hs=1";
      }
      window.location.href = link;
    },
    onLoad () {
      this.sliderControl[0].active = true
      this.startStepsAnimation()
    },
    changeActiveStep (step) {
      this.instructionSteps.forEach(item => item.active = false)
      step.active = true
      this.activeStepNumber = step.id - 1
      clearInterval(this.interval)
    },
    changeSlide (direction) {
      const activeSlide = this.sliderControl.find(item => item.active === true)
      if (direction === 'next') {
        if (this.sliderControl[activeSlide.id] === this.sliderControl[this.sliderControl.length - 1]) {
          this.sliderControl[activeSlide.id].active = false
          this.sliderControl[0].active = true
        } else {
          this.sliderControl[activeSlide.id].active = false
          this.sliderControl[activeSlide.id + 1].active = true
        }
      } else if (direction === 'prev') {
        if (this.sliderControl[activeSlide.id ] === this.sliderControl[0]) {
          this.sliderControl[activeSlide.id].active = false
          this.sliderControl[this.sliderControl.length - 1].active = true
        } else {
          this.sliderControl[activeSlide.id].active = false
          this.sliderControl[activeSlide.id - 1].active = true
        }
      } else {
        this.sliderControl.forEach(item => item.active = false)
        direction.active = true
      }
    },
    startStepsAnimation () {
      this.interval = setInterval(() => {
        if (this.activeStepNumber === null) {
          this.activeStepNumber = 0
        }
        this.instructionSteps[this.activeStepNumber].active = true
        this.activeStepNumber === 0 ? this.instructionSteps[this.instructionSteps.length - 1].active = false : this.instructionSteps[this.activeStepNumber - 1].active = false
        this.activeStepNumber === 3 ? this.activeStepNumber = 0 : this.activeStepNumber++
      }, 3000)
    }
  }
}
</script>

<style lang="scss" scoped>
  .home-hero{
    &.active {
      background-position: center;
      .btn-finding {
        left: 0 !important
      }
      .control {
        left: 68% !important;
        @include max-w-md {
          left: calc(50% - 88px) !important;
          bottom: 12px !important;
        }
      }
      .slide-item {
        &.active {
          .mobile-title {
            position: relative;
            left: 0;
          }
          .content {
            left: 0;
          }
          .wrapper-img, .wrapper-img-secondary {
            right: 0;
          }
          .blur-img {
            transition: 4s ease-out !important;
          }
          .docs {
            top: 30%;
            left: 55%;
            transform: scale(1) rotate(720deg);
            @include max-w-md {
              top: 20%;
              left: 30%;
              transform: scale(.5) rotate(720deg) !important;
            }
          }
          .umbrella {
            left: auto;
            right: -10%;
            top: 0;
            transform: rotate(520deg);
          }
          .bag {
            bottom: 10%;
            left: 60%;
            transform: rotate(340deg) scale(1) !important;
            @include max-w-md {
              transform: rotate(340deg) scale(.5) !important;
              left: 0;
              bottom: auto;
              top: 30%;
            }
          }
          .case {
            left: 20%;
            bottom: 0;
            transform: rotate(200deg);
            @include max-w-md {
              left: 5%;
              bottom: -10%;
              transform: rotate(240deg) scale(0.7) !important;
            }
          }
        }
      }
    }
  }
  .home-hero {
    background: url('~@/assets/img/home/hero/bg-hero-pipe.png') center 2000px / cover no-repeat;
    transition: 1s;
    @include max-w-xs {
      background-image: url('~@/assets/img/home/hero/hero-pipe-mobile.png');
      background-size: cover;
    }
    .container {
      min-height: 100vh;
      justify-content: center;
      max-width: none;
      @include max-w-md {
        height: auto;
        justify-content: flex-start;
      }
    }
    .wrapper {
      max-width: 1100px;
      min-height: 550px;
      max-height: 640px;
      height: 100%;
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-grow: 1;
      @include max-w-md {
        max-height: none;
      }
      .wrapper-btn-finding-global {
        bottom: 20px;
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      .btn-finding {
        z-index: 99;
        max-width: 300px;
        position: absolute;
        left: -2000px;
        bottom: 0;
        transition: 1s;
        @include max-w-md {
          position: relative;
          max-width: none;
          &.global {
            display: none;
          }
        }
        .icon {
          path {
            fill: $dark;
          }
        }
      }
      .btn-purchase {
        position: relative;
        z-index: 99;
        .icon-cart {
          path {
            fill: $white;
          }
        }
      }
      .control {
        z-index: 9;
        position: absolute;
        bottom: 0;
        left: calc(100% + 2000px);
        margin: 0 auto;
        display: flex;
        align-items: center;
        transition: 1s;
        &.desktop {
          @include max-w-md {
            display: none;
          }
        }
        &.mobile {
          @include min-w-md {
            display: none !important;
          }
        }
        .btn-direction {
          display: flex;
        }
        .arrow-icon {
          &:first-child {
            margin-right: 16px;
          }
          path {
            stroke: $orange;
          }
        }
        .control-btn {
          margin-right: 16px;
          border-radius: 50%;
          width: 16px;
          height: 16px;
          background: $orange;
          &.active {
            background: $pink;
          }
        }
      }
      .slide-item {
        width: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include max-w-md {
          padding-bottom: 44px;
          flex-direction: column-reverse;
        }
        &.active {
          position: relative;
        }
        .desktop-title {
          @include max-w-md {
            display: none;
          }
        }
        .mobile-title {
          transition: 1s;
          position: absolute;
          left: -2000px;
          @include min-w-md {
            display: none;
          }
        }
        .content {
          z-index: 1;
          padding: 80px 0;
          transition: 1s;
          position: relative;
          left: -2000px;
          @include max-w-md {
            padding-top: 0;
            padding-bottom: 0;
          }
          .btn-finding {
            @include min-w-md {
              display: none;
            }
          }
          .section-title {
            max-width: 590px;
            @include max-w-md {
              max-width: none;
            }
          }
          .description {
            max-width: 415px;
            @include max-w-md {
              max-width: none;
            }
          }
        }
        .mobile-step-description {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 54px;
          margin-top: 20px;
          z-index: 10;
          background: $white;
          border-radius: $border-radius-base;
          box-shadow: $box-shadow-base;
          padding: 10px 20px;
          @include min-w-md {
            display: none;
          }
          @include max-w-xs {
            margin-top: 10px;
          }
        }
        .wrapper-img-secondary {
          img {
            width: 90%;
            height: 90%;
            &.keys {
              object-fit: contain;
              width: 100%;
              height: 100%;
              transform: rotate(33deg) scale(1.2);
            }
          }
        }
        .wrapper-img, .wrapper-img-secondary {
          transition: 1s;
          position: relative;
          right: -2000px;
          border-radius: 50%;
          width: 528px;
          height: 528px;
          @include max-w-md {
            width: 300px;
            height: 300px;
          }
        }
        .wrapper-img {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          width: 528px;
          height: 528px;
          @include max-w-lg {
            width: 460px;
            height: 460px;
          }
          @include max-w-md {
            margin-bottom: 100px;
            width: 300px;
            height: 300px;
          }
          @include max-w-xs {
            width: 280px;
            height: 280px;
          }
          .main-img {
            object-fit: contain;
            width: 80%;
            height: 80%;
          }
          &::before {
            content: '';
            z-index: 2;
            position: absolute;
            background: rgba(126, 202, 216, 0.16);
            backdrop-filter: blur(6px);
            border-radius: 50%;
            width: 528px;
            height: 528px;
            @include max-w-lg {
              width: 460px;
              height: 460px;
            }
            @include max-w-md {
              width: 300px;
              height: 300px;
            }
          }
          &::after {
            content: '';
            z-index: 2;
            position: absolute;
            width: calc(528px - 20%);
            height: calc(528px - 20%);
            background: linear-gradient(180deg, #FFFFFF 0%, rgba(232, 185, 164, 0.21) 100%);
            border-radius: 50%;
            @include max-w-lg {
              width: calc(460px - 20%);
              height: calc(460px - 20%);
            }
            @include max-w-md {
              width: calc(300px - 20%);
              height: calc(300px - 20%);
            }
          }
          img {
            position: relative;
            z-index: 9;
          }
        }
        .blur-img {
          position: absolute;
          transition: .5s ease-in !important;
        }
        .docs {
          width: 88px;
          z-index: 12;
          top: 50%;
          left: 200%;
          filter: blur(2px);
          transform: scale(0.6);
          @include max-w-xs {
            transform: scale(.2) !important;
          }
        }
        .umbrella {
          z-index: 1;
          width: 159px;
          right: -200%;
          top: 50%;
          opacity: 0.7;
          filter: blur(2px);
          transform: rotate(0);
          @include max-w-xs {
            transform: rotate(160deg) scale(.5) !important;
          }
        }
        .bag {
          width: 158px;
          z-index: 99;
          bottom: 50%;
          left: 200%;
          filter: blur(4px);
          transform: rotate(0) scale(.6);
          @include max-w-xs {
            transform: rotate (0) scale(.2);
          }
        }
        .case {
          z-index: 1;
          width: 211px;
          background: linear-gradient(233.5deg, rgba(255, 255, 255, 0.56) 15.22%, rgba(255, 255, 255, 0) 79.01%);
          opacity: 0.56;
          filter: blur(6px);
          left: 200%;
          bottom: 50%;
          transform: rotate(0);
          @include max-w-md {
            transform: rotate(0) scale(.5) !important;
          }
        }
      }
    }
  }
</style>